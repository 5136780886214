import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import "../../styles/hamburgers.css"
import callicon from "../../images/icons/contact/call-icon.svg"
import Logo from "../../images/logo/logo.inline.svg"
import { StyledButton } from "../styledButton"

const Paragraphs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (min-width: 1024px) {
    justify-content: flex-end;
    width: auto;
    & p:first-child {
      margin-right: 8px;
    }
  }
`

const NavBanner = styled.div`
  background-color: #062964;
  padding: 0 16px;
  & .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .mail-clios {
      display: none;
    }
    & p {
      font-size: 12px;
      color: #fff;
    }
  }

  @media (min-width: 1024px) {
    & .box {
      max-width: 996px;
      margin: 0 auto;
      & .mail-clios {
        font-size: 12px;
        display: block;
        text-decoration: none;
        color: #fff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    & .box {
      max-width: 1200px;
    }
  }
`

const MainNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 16px 16px;
  @media (min-width: 1024px) {
    padding: 10px 0;
  }
`

const Dropdown = styled.li`
  position: relative;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s ease;
    &:hover {
      color: #196fd9;
    }
    &::after {
      content: "\\005E";
      font-size: 16px;
      transform: rotate(180deg);
      transition: transform 0.3s;
      margin-left: 0.5rem;
      color: ${props => (props.font ? "#000" : props.font)};
    }
  }
  &:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
    background-color: #fff;
  }
`

const DropdownMenu = styled.ul`
  list-style: none;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  left: 0;
  transform: translateY(-2px);
  display: none;
  border-radius: 4px !important;
  width: max-content;

  li {
    a {
      justify-content: flex-start;
      padding: 15px 25px;
      color: #000 !important;
      word-break: keep-all;
      &::after {
        display: none;
      }
    }
  }

  li:hover {
    background-color: #196fd9;
    a {
      color: #fff !important;
    }
  }
`

const StyledNav = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #eff2f7;
  transition: box-shadow 0.2s ease;
  &.fixed-bg {
    box-shadow: 0 2px 4px 0 rgba(6, 41, 100, 0.1),
      0 8px 16px 0 rgba(58, 70, 91, 0.1);
  }
  @media (min-width: 1024px) {
    & div.container {
      max-width: 996px;
      margin: 0 auto;
    }
  }
  @media (min-width: 1200px) {
    & div.container {
      max-width: 1200px;
    }
  }
`

const StyledLogo = styled(Logo)`
  max-width: 180px;
  width: 100%;
  @media (min-width: 1024px) {
    max-width: 204px;
  }
`

const NavItems = styled.ul`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  transform: translateY(-100%);
  position: relative;
  z-index: -1;
  display: none;
  transition: all 0.3s ease;
  opacity: 0;
  border-bottom: 2px solid #ddd;
  &.pc-menu {
    display: none;
  }
  @media (min-width: 1024px) {
    display: none;
    &.pc-menu {
      padding: 0 0 0 16px;
      display: flex;
      flex-direction: row;
      opacity: 1;
      transform: translateY(0);
      z-index: 999;
      border-bottom: none;
    }
  }
  &.active {
    display: flex;
    transform: translateY(0);
    opacity: 1;
    z-index: 999;
    @media (min-width: 1024px) {
      display: none;
    }
  }
  & li {
    & a {
      padding: 12px 16px;
      font-size: 16px;
      text-decoration: none;
      color: #062964;
      font-weight: 700;
      cursor: pointer;
      transition: color 0.2s ease, transform 0.2s ease;
      &:hover {
        color: #196fd9;
      }
      @media (min-width: 1024px) {
        font-size: 13px;
      }
      @media (min-width: 1200px) {
        font-size: 16px;
        padding: 12px 20px;
      }
    }
  }
`

const Button = styled(StyledButton)`
  padding: 0;
  margin-left: 16px;
  display: flex;
  align-items: center;
  padding: 14px 40px !important;
  box-shadow: 0 8px 16px 0 rgba(58, 70, 91, 0.1),
    0 2px 4px 0 rgba(6, 41, 100, 0.1);
  color: #fff !important;
  & img {
    margin-right: 8px;
  }
  &.mobile-btn {
    margin-left: 0;
    margin-top: 8px;
    width: calc(100vw - 120px);
    justify-content: center;
  }
`

const Navigation = () => {
  const [isActiveNav, setActiveNav] = useState(false)

  const listenToScroll = () => {
    const position = window.pageYOffset
    if (position > 50) {
      refNav.current.classList.add("fixed-bg")
    } else {
      refNav.current.classList.remove("fixed-bg")
    }
  }

  const refNav = useRef(null)

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll)
    return () => window.removeEventListener("scroll", listenToScroll)
  })

  return (
    <StyledNav ref={refNav}>
      <NavBanner>
        <div className="box">
          <a href="mailto:lorem@ipsum.pl" className="mail-clios">
            lorem@ipsum.pl
          </a>
          <Paragraphs>
            <p>Miałeś wypadek?</p>
            <p>
              Zadzwoń <a href="tel:123456345">123456345</a>. Działamy 24/7
            </p>
          </Paragraphs>
        </div>
      </NavBanner>
      <div className="container">
        <MainNavigation>
          <Link to="/">
            <StyledLogo />
          </Link>
          <button
            className={[
              "hamburger",
              "hamburger--collapse",
              isActiveNav && "is-active",
            ].join(" ")}
            type="button"
            onClick={() => setActiveNav(!isActiveNav)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <NavItems className="pc-menu">
            <li>
              <Link to="/obsluga-wypadkow/">Obsługa wypadków</Link>
            </li>
            <li>
              <Link to="/flota/">Auto zastępcze</Link>
            </li>
            <Dropdown>
              <Link to="/uszkodzony-pojazd/">Odszkodowania</Link>
              <DropdownMenu className="dropdown-menu">
                <li>
                  <Link to="/uszczerbek-na-zdrowiu/">Obrażenia ciała</Link>
                </li>
                <li>
                  <Link to="/uszkodzony-pojazd/">Uszkodzony pojazd</Link>
                </li>
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <Link to="/współpraca/">Współpraca</Link>
              <DropdownMenu className="dropdown-menu">
                <li>
                  <Link to="/franczyza/">Franczyza</Link>
                </li>
                <li>
                  <Link to="/współpraca/">Zostań naszym Agentem</Link>
                </li>
              </DropdownMenu>
            </Dropdown>
            <li>
              <Button as="a" href="tel:883882500">
                <img src={callicon} alt="zadzwoń do nas" /> Zadzwoń
              </Button>
            </li>
          </NavItems>
        </MainNavigation>
        <NavItems className={isActiveNav && "active"}>
          <li>
            <Link to="/obsluga-wypadkow/">Obsługa wypadków</Link>
          </li>
          <li>
            <Link to="/flota/">Flota</Link>
          </li>
          <li>
            <Link to="/uszkodzony-pojazd/">Odszkodowania</Link>
          </li>
          <li>
            <Link to="/kredyty-frankowe/">Kredyty frankowe</Link>
          </li>
          <li>
            <Link to="/współpraca/">Współpraca</Link>
          </li>
          <li>
            <Button as="a" href="tel:883882500" className="mobile-btn">
              <img src={callicon} alt="zadzwoń do nas" /> Zadzwoń
            </Button>
          </li>
        </NavItems>
      </div>
    </StyledNav>
  )
}

export default Navigation
