import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`

html,body, *::after, *::before{
    margin:0;
    padding:0;
    display:block;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    overflow-x:hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body{
  background-color: #EFF2F7;
}
p{
    font-size: 16px;
    line-height: 25px;
    margin-block-start: 0;
    margin-block-end: 0;
}

h1{
  margin-block-start: 0;
    margin-block-end: 0;
}

h2{
  margin-block-start: 0;
    margin-block-end: 0;
}

ul{
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

button{
    border: none;
    background-color: transparent;
}

img{
    height: auto;
    max-width:100%;
}
a {
    color: inherit;
    display: inline-block;
}
h3{
    margin:0;
}
`

export default GlobalStyle
