import styled from "styled-components"

export const StyledButton = styled.button`
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.4px;
  padding: 13px 24px;
  color: #fff;
  border-radius: 4px;
  background-image: linear-gradient(to left, #195bc5 100%, #196fd9);
  cursor: pointer;
  transition: 0.2s ease;
  text-decoration: none;
  outline: none;
  &:hover {
    transform: scale(1.08);
  }
`
