import React, { useEffect } from "react"
import PropTypes from "prop-types"
import GlobalStyle from "../../styles/GlobalStyles"
import Navigation from "./Navigation"
import Footer from "./Footer"
import SEO from "../../components/seo"
import ls from "@livesession/sdk"

const Layout = ({ children, hideFooter }) => {
  useEffect(() => {
    ls.init("9ce95a3c.a8aa1388")
    ls.newPageView()
  }, [])

  return (
    <>
      <GlobalStyle />
      <Navigation />
      <SEO />
      {children}
      {!hideFooter && <Footer />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideFooter: PropTypes.bool,
}

Layout.defaultProps = {
  hideFooter: false,
}
export default Layout
