import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logo from "../../images/logo/logo-white.svg"
import fb from "../../images/icons/contact/fb.svg"

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: #062964;
  color: #fff;
  @media (min-width: 1024px) {
    justify-content: center;
    align-items: center;
  }
`

const Container = styled.div`
  padding: 22px 16px 0;
  @media (min-width: 768px) {
    padding: 60px 16px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
  }
  @media (min-width: 1024px) {
    grid-column-gap: 65px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
    grid-column-gap: 135px;
  }
`

const Address = styled.p`
  padding: 4px 0;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  & h3 {
    margin-bottom: 8px;
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: 700;
    @media (min-width: 768px) {
      margin-bottom: 10px;
    }
  }
  & .logo-footer {
    max-width: 200px;
    margin-bottom: 8px;
  }
  ${Address}:first-of-type {
    border-top: 2px solid #506892;
    padding-top: 8px;
  }
`
const Desc = styled.p`
  border-top: 2px solid #506892;
  padding-top: 12px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
`

const StyledList = styled.ul`
  border-top: 2px solid #506892;
  & li {
    & a {
      text-decoration: none;
      padding: 8px 0;
      @media (min-width: 768px) {
        padding: 6px 0;
      }
    }
  }
`

const Copy = styled.div`
  border-top: 2px solid #506892;
  margin: 0 16px 32px;
  text-align: center;
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 16px 32px;
    width: 100%;
  }
  & p {
    margin-top: 8px;
    font-style: italic;
    font-size: 14px;
    letter-spacing: 0.26px;
    &:last-child a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    @media (min-width: 768px) {
      margin-top: 12px;
    }
    &:first-child {
      padding-top: 8px;
      @media (min-width: 768px) {
        padding-top: 12px;
      }
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Box>
          <img src={logo} alt="logo - footer" className="logo-footer" />
          <Desc>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis,
            soluta nesciunt! Recusandae corrupti assumenda dolorum sed suscipit.
            Blanditiis ipsum illo voluptas aliquid et consequuntur. Maiores
            autem iusto adipisci omnis facilis.
          </Desc>
        </Box>
        <Box>
          <h3>Nawigacja</h3>
          <StyledList>
            <li>
              <Link to="/obsluga-wypadkow/">Obsługa wypadków</Link>
            </li>
            <li>
              <Link to="/flota/">Flota</Link>
            </li>
            <li>
              <Link to="/uszkodzony-pojazd/">Odszkodowania</Link>
            </li>
            <li>
              <Link to="/współpraca/">Współpraca</Link>
            </li>
          </StyledList>
        </Box>
        <Box>
          <h3>Kontakt</h3>
          <Address>
            Telefon 24/7: <a href="tel:123456789">123456789</a>
          </Address>
          <Address>ul. Testowa 1, 12345 Miasteczko</Address>
          <Address>
            <a href="mailto:lorem@ipsum.pl">lorem@ipsum.pl</a>
          </Address>
          <Address>
            <a
              href="https://www.facebook.com/LiveSessionIO/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ cursor: "pointer" }}
            >
              <img src={fb} alt="facebook" />
            </a>
          </Address>
        </Box>
      </Container>
      <Copy>
        <p>Copyright ® 2020 Test. Wszelkie prawa zastrzeżone.</p>
        <p>
          Projekt i wykonanie{" "}
          <a
            href="https://loremipsum.pl/"
            target="_blank"
            rel="dofollow noopener noreferrer"
          >
            loremipsum.pl
          </a>
        </p>
      </Copy>
    </StyledFooter>
  )
}

export default Footer
